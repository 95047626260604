import styled, { css } from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { textSM } from 'styles/typography';

export const FooterBase = styled.footer`
  background-color: ${props => props.theme.primary};
  padding-bottom: 100px;
  margin-top: ${props => (props.noFooterMargin ? '0' : '200px')};
`;

export const Content = styled.div(
  props => css`
    display: flex;
    padding-top: 20px;
    justify-content: space-between;

    .logo {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.05em;

      display: flex;
      flex-direction: column;
      img {
        width: 60px;
        height: 25px;
      }

      span {
        margin-top: 8px;
      }
    }

    .sub-heading {
      ${textSM};
      color: ${rgba(props.theme.textSecondary, 0.7)};
      letter-spacing: 0.31px;
      line-height: 23px;
      max-width: 240px;
      margin-top: 12px;
    }
  `
);

export const Divider = styled.hr`
  border: none;
  border-bottom: 1px solid ${props => props.theme.textSecondary};
  height: 1px;
  width: 110%;
  transform: translateX(-5%);
  margin-top: 64px;
`;
