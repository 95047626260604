import React from 'react';
import Container from 'elements/Container';
import { HOME_URL } from 'data/urls';

import { Link, withPrefix } from 'gatsby';
import Links from './Links';
import Next from './Next';
import { FooterBase, Content } from './style';

export default function Footer({ links, noFooterMargin = false }) {
  return (
    <FooterBase noFooterMargin={noFooterMargin}>
      <Container>
        <Next />
        <Content>
          <div>
            <Link className="logo" to={HOME_URL}>
              <img src={withPrefix('images/icons/goto_white.png')} alt="GoTo Logo" />
            </Link>
            <div className="sub-heading">
              Go Far, Go Together
            </div>
          </div>
          <Links links={links} />
        </Content>
      </Container>
    </FooterBase>
  );
}
