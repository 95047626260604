import React from 'react';

import { FloatingBar } from './style';

export default function Footer() {
  return (
    <FloatingBar>
      <div className="bar-text">
        <span>Go Far, Go Together</span>
      </div>
    </FloatingBar>
  );
}
