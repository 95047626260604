import { createGlobalStyle, css } from 'styled-components';
import { rgba } from 'polished';
import { removeLink } from './mixins';

export const Type = createGlobalStyle(
  props => css`
    body {
      font-family: MaisonNeue, Helvetica Neue, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      font-size: 16px;
      line-height: 28px;
      background-color: ${props.theme.bgColor};
      color: ${rgba(props.theme.textMain, 0.6)};
      ${removeLink};
    }

    h1 {
      font-size: 32px;
      font-weight: 300;
      line-height: 48px;
      color: ${rgba(props.theme.textMain, 1)};
    }

    h2 {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      color: ${rgba(props.theme.textMain, 0.9)};
    }

    h3 {
      font-size: 18px;
      line-height: 32px;
      font-weight: 400;
      color: ${rgba(props.theme.textMain, 0.7)};
    }

    h4 {
      font-size: 16px;
      font-weight: 400;
      color: ${rgba(props.theme.textMain, 0.7)};
    }

    h5 {
      font-size: 16px;
      color: ${rgba(props.theme.textMain, 0.5)};
      font-weight: 500;
    }

    a {
      text-decoration: none;
      color: ${props.theme.primary};
    }

    a:hover {
      text-decoration: none;
      color: ${props => props.theme.primaryHover};
    }

    img {
      max-width: 100%;
    }

    table {
      margin-top: 16px;
    }
  `
);
