/* eslint-disable no-restricted-globals */
const dayjs = require('dayjs');
const { LOGO_PLACEHOLDER } = require('../data/urls');

function getLinksFileMap(links) {
  return (
    links &&
    links.edges.reduce((acc, doc) => {
      const { fields, frontmatter } = doc.node;
      const { fileName, dirName } = fields;
      const key = dirName + fileName;
      acc[key] = {
        ...fields,
        ...frontmatter,
      };
      return acc;
    }, {})
  );
}

function getDocsLinks(list, content = [], dirName) {
  const linksFileMap = getLinksFileMap(list);
  const pages =
    content &&
    content.reduce((acc, page) => {
      const { Name, Category } = page;
      const name = dirName + Name;
      acc[Category] = acc[Category] || [];
      acc[Category].push({ ...linksFileMap[name], ...page });
      return acc;
    }, {});
  return (
    pages &&
    Object.keys(pages).map(name => ({
      name,
      pages: pages[name],
    }))
  );
}

function getDocsList(list, pinned = []) {
  const pages = list.edges;
  const sectionMap = pinned.reduce((acc, data) => {
    const { Section, Product, Heading } = data;
    if (!Section) return acc;
    acc[Section] = acc[Section] || [];
    const doc = pages.find(({ node }) => {
      const { frontmatter } = node;
      const productName = frontmatter.breadcrumb[0];
      return (
        productName &&
        Product &&
        Heading &&
        productName.toLowerCase() === Product.toLowerCase() &&
        Heading.toLowerCase() === frontmatter.title.toLowerCase()
      );
    });
    if (doc) {
      acc[Section].push({
        ...doc.node.frontmatter,
        ...doc.node.fields,
        ...data,
      });
    }
    return acc;
  }, {});
  return Object.keys(sectionMap).map(name => ({
    name,
    pages: sectionMap[name],
  }));
}

function getNextPrevPage(sections = [], slug) {
  if (!sections) return { next: null, prev: null };
  const list = sections.map(({ items }) => items).flat();
  const currentIndex = list.findIndex(x => x.slug === slug);
  const next = currentIndex < list.length - 1 ? list[currentIndex + 1] : null;
  const prev = currentIndex > 0 ? list[currentIndex - 1] : null;
  return { next, prev };
}

function compareIndex(a, b) {
  if (isFinite(a.index - b.index)) return a.index - b.index;
  return isFinite(a.index) ? -1 : 1;
}

function getLinks(edges = [], orders = []) {
  const links = edges.map(({ node }) => {
    const { fields, frontmatter } = node;
    const { slug, collection } = fields;
    const { title } = frontmatter;
    const order = orders.find(
      data =>
        (data.Product && data.Product.toLowerCase() === title.toLowerCase()) ||
        (data.Solution && data.Solution.toLowerCase() === title.toLowerCase())
    );
    const index = order && !isNaN(order.index) ? order.index : NaN;
    return { index, href: slug, collection, ...node.frontmatter };
  });
  const sorted = [...links].sort(compareIndex);
  return sorted.reduce((acc, link) => {
    acc[link.collection] = acc[link.collection] ? acc[link.collection] : [];
    acc[link.collection].push(link);
    return acc;
  }, {});
}

function getProductList(list, orders = []) {
  const newList = list
    .map(({ node }) => {
      const { title } = node.frontmatter;
      const order = orders.find(
        ({ Product }) => Product.toLowerCase() === title.toLowerCase()
      );
      const index = order && !isNaN(order.index) ? order.index : NaN;
      return {
        index,
        node,
      };
    })
    .sort(compareIndex);

  return newList.reduce((acc, product) => {
    const { frontmatter, fields } = product.node;
    const { category } = frontmatter;
    acc[category] = acc[category] || [];
    acc[category].push({
      ...fields,
      ...frontmatter,
    });
    return acc;
  }, {});
}

function getFilePath(collection, slug, originalSlug) {
  let url = '';
  switch (collection) {
    case 'pages': {
      url = slug;
      break;
    }
    case 'architecture': {
      url = `/architecture/${originalSlug}`;
      break;
    }
    case 'docs': {
      const slugArr = slug.split('/');
      slugArr.splice(2, 0, 'docs');
      const newSlug = slugArr.join('/');
      url = `/docs${newSlug}`;
      break;
    }
    default: {
      // For products and solutions
      url = `/${collection}${slug}`;
    }
  }
  return url.replace(/\?/g, '').toLocaleLowerCase();
}

function getDocsIndexPage(name) {
  const url = `/docs/${name}`;
  return url.replace(/\?/g, '').toLocaleLowerCase();
}

function getProductListFromDocs(list, meta = []) {
  const ProductMap = list.edges.reduce((acc, { node }) => {
    const { frontmatter } = node;
    const { toc, breadcrumb, path } = frontmatter;
    const FolderName = breadcrumb[0];
    const metaData = meta.find(item => item.Folder_Name === FolderName);
    const cat = (metaData && metaData.Category_Name) || 'Documents';
    const logo = (metaData && metaData.Logo) || LOGO_PLACEHOLDER;
    const index = metaData && !isNaN(metaData.index) ? metaData.index : NaN;
    const tocSlug =
      (toc[0].items && toc[0].items[0] && toc[0].items[0].slug) || toc[0].slug;
    const tocLink = getFilePath('docs', `${path}-${tocSlug}`);

    if (!acc[FolderName]) {
      acc[FolderName] = {
        index,
        name: FolderName,
        logo,
        category: cat,
        href: tocLink,
      };
    }
    return acc;
  }, {});

  const sortedProductMap = Object.values(ProductMap).sort(compareIndex);
  const catMap = sortedProductMap.reduce((acc, product) => {
    const { category } = product;
    acc[category] = acc[category] || [];
    acc[category].push(product);
    return acc;
  }, {});

  return Object.keys(catMap)
    .map(name => ({
      name,
      products: catMap[name],
    }));
}

function getNewletterList(news) {
  return news.edges
    .map(({ node }) => ({ ...node.frontmatter, ...node.fields }))
    .sort((a, b) => (dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1));
}

function getNextPrevNewsLetter(links = [], date) {
  if (!links) return { next: null, prev: null };
  const currentIndex = links.findIndex(x => x.date === date);
  const prev = currentIndex < links.length - 1 ? links[currentIndex + 1] : null;
  const next = currentIndex > 0 ? links[currentIndex - 1] : null;
  return { next, prev };
}

module.exports = {
  getFilePath,
  getDocsLinks,
  getDocsList,
  getProductList,
  getLinks,
  getNextPrevPage,
  getProductListFromDocs,
  compareIndex,
  getNewletterList,
  getNextPrevNewsLetter,
  getDocsIndexPage,
};
