import 'prism-themes/themes/prism-material-light.css';
import { darken } from 'polished';

const primary = 'rgb(52, 117, 224)';

const themes = {
  light: {
    textMain: '#000000',
    textMainLight: 'rgba(0, 0, 0, 0.83)',
    textSecondary: '#ffffff',
    textSecondaryLight: 'rgba(255, 255, 255, 0.83)',
    bgColor: '#FFFFFF',
    primary,
    primaryHover: darken(0.1, primary),
    borderColor: 'rgba(0, 0, 0, .10)',
    boxShadow: '#000000',
    headingText: '#000000',
  },
  dark: {
    textMain: '#FFFFFF',
    textMainLight: 'rgba(255, 255, 255, 0.83)',
    textSecondary: '#000000',
    textSecondaryLight: 'rgba(0, 0, 0, 0.83)',
    bgColor: '#000000',
    primary: 'rgb(77, 133, 244)',
    borderColor: 'rgba(255, 255, 255, 0.83)',
    boxShadow: '#FFFFFF',
    headingText: '#FFFFFF',
  },
};

export default function Theme(theme = 'light') {
  return themes[theme];
}
