import React from 'react';
import Container from 'elements/Container';
import { navigate, Link, withPrefix } from 'gatsby';
import {
  HOME_URL,
  DOCS_URL,
  LOGIN_URL,
} from 'data/urls';
import { useAuthValue } from 'utils/auth';
import { logout } from 'utils/firebase';

import Menu from './Menu';
// import Search from './Search';
import { Base, Content, Button, PageContent } from './style';

const LogoutButton = ({ location }) => (
  <Button
    primary
    onClick={() => logout(() => navigate(LOGIN_URL, { state: { origin: location.pathname || '/' } }))}
  >
    LOGOUT
  </Button>
);

function Navbar({ isSticky = false, location }) {
  const { isLoggedIn } = useAuthValue();

  return (
    <Base isSticky={isSticky}>
      <Container style={{ width: '96%', maxWidth: 'unset' }}>
        <Content>
          <div className="nav-links">
            <Link to={HOME_URL} className="logo">
              <img src={withPrefix('images/icons/goto_green.svg')} alt="logo" />
            </Link>
            {isLoggedIn && (
              <ul className="links-group">
                <Menu text="GoTo Tech Office" href={DOCS_URL} />
              </ul>
            )}
          </div>
          <div className="right-div">
            {/* <Search /> */}
            {isLoggedIn && <LogoutButton location={location} />}
          </div>
        </Content>
      </Container>
    </Base>
  );
}

export default Navbar;

export { PageContent };
