import { css } from 'styled-components';
import { heading2 } from './headings';
import { headSM } from './typography';

export const card = () => css`
  border: solid 1px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 32px 24px 24px;
  min-height: 200px;
`;

export const grid = (
  maxWidth = '400px',
  count = 'auto-fit',
  gap = '56px'
) => css`
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(${count}, minmax(${maxWidth}, 1fr));
  padding-inline-start: 0;
  list-style: none;
  grid-gap: ${gap};
`;

export const flexCenter = (direction = 'row') => css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${direction};
`;

export const removeLink = css`
  .anchor.before {
    display: none;
  }
`;

export const topText = (color = '#000000') => css`
  margin: 0;
  ${headSM};
  color: ${color};
  letter-spacing: 1.07px;
  line-height: 31px;
  text-align: center;
  margin-bottom: 12px;
  text-transform: uppercase;
  opacity: 0.4;
  ${removeLink}
`;

export const blockHeading = (maxWidth = '500px') => css`
  ${heading2};
  text-align: center;
  max-width: ${maxWidth};
  font-size: 32px;
  margin-top: 0px;
  ${removeLink}
`;

export const circularImg = (size = '100%') => css`
  border-radius: 50%;
  height: ${size};
  width: ${size};
  object-fit: cover;
`;

export const circleBullet = (lineHeight = 16) => {
  const topPostion = lineHeight / 2;
  return css`
    ul {
      list-style-type: none;
      padding-left: 10px;
      li {
        margin-left: 0;
        padding-left: 16px;
        background-image: url('/images/icons/oval.svg');
        background-position: 0 ${`${topPostion}px`};
        background-size: 6px 6px;
        background-repeat: no-repeat;
      }
    }
  `;
};
