import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  height: 100%;
`;
export default Container;
