import styled, { css } from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { textSM, headSM } from 'styles/typography';

export const FooterLinks = styled.div(
  props => css`
    display: flex;
    justify-content: space-between;

    .see-all {
      ${textSM};
      color: ${rgba(props.theme.textSecondary, 0.9)};
      width: fit-content;
      line-height: 16px;
      border-bottom: 1px solid ${rgba(props.theme.textSecondary, 0.9)};
      &:hover {
        color: ${props.theme.textSecondary};
      }
    }

    h3 {
      ${headSM};
      color: ${props => props.theme.textSecondary};
      font-size: 16px;
      letter-spacing: 1.23px;
      line-height: 31px;
      text-transform: uppercase;
      padding-right: 12px;
      margin-bottom: 8px;
      margin-top: 0;
    }

    .links-group {
      display: flex;
      flex-direction: column;
      ${textSM};
      color: ${rgba(props.theme.textSecondaryLight, 0.7)};
      line-height: 19px;
      margin-right: 108px;
      a:not(.see-all):hover {
        color: ${rgba(props.theme.textSecondaryLight, 1)};
      }
      a:not(.see-all) {
        text-decoration: none;
        color: inherit;
        margin-bottom: 6px;
      }
    }
  `
);
