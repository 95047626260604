import styled, { css } from 'styled-components';
import ButtonBase from 'elements/Button';
import { alignFlexCenter, marginBlock0 } from 'styles/utilities';
import { flexCenter } from 'styles/mixins';
import rgba from 'polished/lib/color/rgba';
import { textSM, headSM, linkPrimary, textMD } from 'styles/typography';
import { Link } from 'gatsby';

export const Base = styled.nav(
  props => css`
    position: fixed;
    width: 100%;
    height: 66px;
    z-index: 10;
    border-bottom: 1px solid ${props.theme.borderColor};
    background-color: ${props.theme.bgColor};
    box-shadow: 0 8px 25px 0px rgba(0, 0, 0, 0.05);
  `
);

export const Content = styled.div`
  ${alignFlexCenter};
  height: 100%;
  justify-content: space-between;

  .logo {
    color: #424242;
    font-size: 21px;
    font-weight: 900;
    letter-spacing: 1.38px;
    margin-right: 32px;
    cursor: pointer;
    ${alignFlexCenter};
    height: 55%;
    ${alignFlexCenter};

    img {
      max-height: 96%;
      object-fit: contain;
    }

    span {
      color: ${props => props.theme.primary};
      font-size: 22px;
      font-weight: 600;
      margin-left: 8px;
      margin-top: -0.5px;
      letter-spacing: 1.38px;
    }
  }

  .nav-links,
  .right-div {
    ${alignFlexCenter};
    height: 100%;
  }

  .links-group {
    list-style: none;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 0;
    ${marginBlock0};
  }
`;

export const Button = styled(ButtonBase)`
  font-size: 11px;
  padding: 10px 12px;
  margin-left: 42px;
`;

export const PageContent = styled.div`
  padding-top: 66px;
`;

export const NavLinkBase = styled.li`
  height: 100%;
  position: relative;
  padding: 0 16px;

  & + & {
    margin-left: 4px;
  }

  & > a {
    ${textSM};
    line-height: 19px;
    height: 100%;
    ${flexCenter()};
    border-bottom: 2px solid
      ${props => (props.isActive ? props.theme.primary : props.theme.bgColor)};
    padding-top: 4px;
  }

  &:hover {
    & > span {
      color: ${props => props.theme.primary};
    }
  }
`;

export const DropDownBase = styled.div(
  props => css`
    position: fixed;
    background: ${props.theme.bgColor};
    top: 66px;
    left: 0;
    border: 1px solid ${props => props.theme.borderColor};
    box-shadow: 0 8px 25px 0px rgba(0, 0, 0, 0.05);
    z-index: 5;
    width: 100vw;
    min-width: 400px;
    display: ${props => (props.isOpen ? 'flex' : 'none')};
    height: max-content;
    border-top: 0px;
    cursor: initial;
    flex-direction: column;
    align-items: flex-start;

    ul {
      padding-left: 0;
      display: flex;
      flex-direction: column;
    }

    li {
      list-style: none;
      margin: 24px 0 0 0;
    }

    .links {
      margin: 0 auto;
      width: 80%;
      display: flex;
      justify-content: flex-start;
      padding: 40px;
      align-items: flex-start;
    }

    .view-all {
      width: 100%;
      ${textMD};
      ${linkPrimary};
      font-weight: 300;
      line-height: 1.25;
      letter-spacing: 0.25px;
      text-align: center;
      padding: 18px;
      border-top: 1px solid ${props.theme.borderColor};
    }
  `
);

export const StyledLink = styled(Link)(
  props => css`
    display: flex;
    align-items: ${props.isTagLine ? 'flex-start' : 'center'};
    justify-content: flex-start;

    img {
      height: 28px;
      width: 28px;
      object-fit: contain;
      margin-right: 8px;
      margin-top: -4px;
      margin-left: -4px;
      opacity: 0.7;
    }

    .tagline {
      ${textMD};
      font-size: 12px;
      line-height: 1.4;
      letter-spacing: 0.2px;
      margin-top: 4px;
      margin-bottom: 8px;
      max-width: 250px;
    }

    .nav-link {
      ${alignFlexCenter};
      height: max-content;
    }

    .title {
      ${textMD};
      color: rgba(0, 0, 0, 8);
      line-height: 20px;
      margin-right: 6px;
    }

    .badge {
      ${headSM};
      background-color: ${props.theme.primary};
      border-radius: 5px;
      color: rgba(255, 255, 255, 0.9);
      font-size: 9px;
      letter-spacing: 0.15px;
      text-align: center;
      padding: 2px 4px;
      line-height: 8px;
    }

    &:hover {
      .title {
        color: ${props.theme.primary} !important;
      }
    }
  `
);

export const DropDownLinks = styled.div(
  props => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 300px;
    align-items: flex-start;
    height: min-content;

    ul {
      margin: 0;
    }
    h5 {
      ${textSM};
      font-size: 12px;
      color: ${rgba(props.theme.textMain, 0.4)};
      line-height: 16px;
      text-transform: uppercase;
      margin: 0;
    }

    & + & {
      margin-left: 60px;
    }
  `
);

export const SearchDiv = styled.div`
  position: relative;

  input {
    border-radius: 4px;
    color: transparent;
    outline: none;
    width: 40px;
    padding: 8px 12px;
    transition: width 0.4s ease-in-out;
    border: 0px;
  }

  input:hover {
    background: #e8eaed;
  }

  .input-focus {
    padding: 8px 12px 8px 40px;
    max-width: 400px;
    width: 20vw;
    ${textSM};
    letter-spacing: 0.22px;
    border: 1px solid rgb(204, 204, 204);
    background: ${props => props.theme.bgColor} !important;
  }

  ul {
    position: absolute;
    top: 100%;
    background: ${props => props.theme.bgColor};
    border-radius: 0px;
    box-shadow: 3px 5px 25px 0px rgba(0, 0, 0, 0.05);
    list-style: none;
    z-index: 20;
    width: 100%;
    ${marginBlock0};
    padding: 8px 0 12px 0px;
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

export const DropDownItem = styled.li`
  padding: 8px 24px;
  line-height: 1;
  cursor: pointer;

  strong {
    font-weight: 500;
  }
  a {
    ${textSM};
    color: ${props =>
    props.isSelected ? props.theme.primary : rgba(props.theme.textMain, 0.7)};
    letter-spacing: 0.22px;
    line-height: 1;
  }

  &:hover {
    a {
      color: ${props => props.theme.primary};
    }
  }
`;
