import React from 'react';

// import { TEAM_URL } from 'data/urls';

import { FooterLinks } from './style';

// const companyLinks = [
//   { title: 'Team', href: TEAM_URL },
// ];

export default function Footer() {
  return (
    <FooterLinks>
      {/* <div>
        <h3>Engage</h3>
        <div className="links-group">
          {companyLinks.map(link => {
            return (
              <a href={link.href} key={`${link.href}-${link.title}`} target="__blank">
                {link.title}
              </a>
            );
          })}
        </div>
      </div> */}
    </FooterLinks>
  );
}
