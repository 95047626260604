import React from 'react';

import 'styles/normalize.css';
import { Fonts } from './fonts';
import { Type } from './type';

export default function Main() {
  return (
    <>
      <Fonts />
      <Type />
    </>
  );
}
