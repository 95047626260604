import styled from 'styled-components';

const BtnBase = styled.a(
  ({
    borderRadius = '3.81px',
    primary = false,
    primaryInvert = false,
    paddingY = '12px',
    paddingX = '16px',
    fontSize = '14px',
    lineHeight = 1,
    minWidth = 'auto',
    ...props
  }) => `
    background: ${primary ? props.theme.primary : props.theme.bgColor};
    border-radius: ${borderRadius};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${
  primary
    ? props.theme.textSecondary
    : primaryInvert
      ? props.theme.primary
      : props.theme.textMainLight
};
    line-height: ${lineHeight};
    font-weight: 300;
    letter-spacing: 0.24px;
    cursor: pointer;
    font-size: ${fontSize};
    padding: ${paddingY} ${paddingX};
    white-space: nowrap;
    min-width: ${minWidth};
    border: 1px solid ${
  primary || primaryInvert ? props.theme.primary : props.theme.borderColor
};

    &:hover {
      background: ${primary ? props.theme.primaryHover : props.theme.bgColor};
      border-color: ${primary ? props.theme.primaryHover : props.theme.primary};
      color: ${primary ? props.theme.textSecondary : props.theme.primary};
    }
`
);

export default BtnBase;
