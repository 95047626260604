import { css } from 'styled-components';
import rgba from 'polished/lib/color/rgba';

export const headSM = css`
  color: ${props => rgba(props.theme.textMain, 0.7)};
  font-size: 14px;
  font-weight: 500;
`;

export const headMD = css`
  color: ${props => rgba(props.theme.textMain, 0.9)};
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
`;

export const headLG = css`
  color: ${props => rgba(props.theme.textMain, 0.9)};
  font-size: 24px;
  font-weight: 500;
`;

export const headXL = css`
  color: ${props => rgba(props.theme.textMain, 0.9)};
  font-size: 28px;
  font-weight: 500;
`;

export const headXXL = css`
  color: ${props => rgba(props.theme.textMain, 0.9)};
  font-size: 32px;
  line-height: 48px;
  font-weight: 500;
`;

export const headML = css`
  color: ${props => rgba(props.theme.textMain, 0.9)};
  font-size: 44px;
  line-height: 54px;
  font-weight: 500;
  letter-spacing: 0.3px;
`;

export const textSM = css`
  color: ${props => rgba(props.theme.textMain, 0.7)};
  font-size: 14px;
  font-weight: 300;
`;

export const textMD = css`
  color: ${props => rgba(props.theme.textMain, 0.6)};
  font-size: 16px;
  font-weight: 300;
`;

export const textXL = css`
  color: ${props => rgba(props.theme.textMain, 0.9)};
  font-size: 28px;
  font-weight: 300;
`;

export const textBold = css`
  font-weight: 600;
  font-family: 'MaisonNeue-Book';
`;

export const linkPrimary = css`
  font-weight: 500;
  color: ${props => props.theme.primary};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.primaryHover};
  }
`;
