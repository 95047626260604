import { css } from 'styled-components';

export const marginBlock0 = css`
  margin-block-start: 0;
  margin-block-end: 0;
`;

export const alignFlexEnd = css`
  display: flex;
  align-items: flex-end;
`;

export const alignFlexCenter = css`
  display: flex;
  align-items: center;
`;

export const justifyFlexCenter = css`
  display: flex;
  justify-content: center;
`;

export const flexWrap = css`
  display: flex;
  flex-wrap: wrap;
`;
