import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql, withPrefix } from 'gatsby';
import { ThemeProvider } from 'styled-components';

import AuthProvider from 'utils/auth';
import { getLinks } from 'utils/links';
// import Feedback from 'components/Feedback';
import Theme from 'styles/theme';
import Main from 'styles/main';

import Header, { PageContent } from 'components/Header';
import Footer from 'components/Footer';

function Query() {
  return useStaticQuery(graphql`
    query FooterLinks {
      list: allMarkdownRemark(
        filter: { fields: { collection: { in: ["solutions"] } } }
      ) {
        edges {
          node {
            frontmatter {
              title
              category
              logo
              tagline
              badge
            }
            fields {
              slug
              collection
            }
          }
        }
      }
    }
  `);
}

function Layout({
  children,
  title,
  noFooterMargin,
  removeHeaderFooter = false,
  location,
}) {
  const data = Query();
  const theme = Theme();
  const links = getLinks(data.list.edges, data.order?.document?.content);
  const pageTitle = title ? `${title} | GoTo Tech Office` : 'GoTo Tech Office';

  return (
    <AuthProvider location={location}>
      <ThemeProvider theme={theme}>
        <Helmet title={pageTitle}>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, minimum-scale=1.0" />
          <meta name="description" content="Curated Engineering Documents" />
          <meta name="keywords" content="documentations, engineering" />
          <link href={withPrefix('images/icons/goto_favicon.png')} rel="icon" type="image/png" />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="http://gdp.golabs.io/" />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:logo" content={withPrefix('images/icons/goto_favicon.png')} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="http://gdp.golabs.io/" />
          <meta property="twitter:title" content={pageTitle} />
        </Helmet>
        <Main />
        {!removeHeaderFooter && <Header links={links} location={location} />}
        <PageContent>{children}</PageContent>
        {!removeHeaderFooter && (
          <>
            <Footer links={links} noFooterMargin={noFooterMargin} />
            {/* <Feedback /> */}
          </>
        )}
      </ThemeProvider>
    </AuthProvider>
  );
}

export default Layout;
