import { withPrefix } from 'gatsby';
import { createGlobalStyle } from 'styled-components';

export const Fonts = createGlobalStyle`
/* We need this font for bold with 600 weight */
@font-face {
  font-family: 'MaisonNeue-Book'; 
  src: url(${withPrefix('/font/MaisonNeue-Book.otf')}) format('opentype');
};

@font-face {
  font-family: 'MaisonNeue';
  src: url(${withPrefix('/font/MaisonNeue-Book.otf')}) format('opentype');
}

@font-face {
  font-family: 'MaisonNeue';
  font-weight: 400;
  src: url(${withPrefix('/font/MaisonNeueWEB-Medium.otf')}) format('opentype');
}

@font-face {
  font-family: 'MaisonNeue';
  font-weight: 500;
  src: url(${withPrefix('/font/MaisonNeue-Demi.otf')}) format('opentype');
}

@font-face {
  font-family: 'MaisonNeue';
  font-weight: 900;
  src: url(${withPrefix('/font/MaisonNeue-Bold.otf')}) format('opentype');
}

@font-face {
  font-family: 'MaisonNeue';
  font-style: italic;
  src: url(${withPrefix('/font/MaisonNeue-BookItalic.otf')}) format('opentype');
}
* {
  box-sizing: border-box;
}

  body {
    overflow-x: hidden;
  }
`;
