import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { textXL, textBold } from 'styles/typography';
import { alignFlexCenter } from 'styles/utilities';

export const FloatingBar = styled.div`
  transform: translateY(-50%);
  background: ${props => props.theme.textSecondary};
  border-radius: 11px;
  border: 1px solid ${props => props.theme.borderColor};
  box-shadow: ${props =>
    `3px 22px 28px 0px ${rgba(props.theme.boxShadow, 0.16)}`};
  min-height: 166px;
  width: 100%;
  display: flex;
  padding: 40px 100px;
  height: fit-content;
  & > div {
    flex: 1;
    ${alignFlexCenter};
    justify-content: center;
  }

  .bar-text {
    ${textXL};
    letter-spacing: 0.24px;
    line-height: 41px;
    strong {
      ${textBold};
    }
  }

  .btn-grp {
    a + a {
      margin-left: 40px;
    }

    a {
      font-size: 16px;
      padding: 14px 32px;
      line-height: 1.25;
    }
  }
`;
