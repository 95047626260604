import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { NavLinkBase } from './style';

function getIsActive(currentText, pathname) {
  const pathArr = pathname.split('/');
  if (
    currentText === 'documentation' &&
    (pathArr[1] === 'docs' || pathArr[3] === 'docs')
  ) {
    return true;
  }
  if (
    pathArr[1] === 'products' &&
    currentText === 'products' &&
    pathArr[3] !== 'docs'
  ) {
    return true;
  }
  if (pathArr[1] === 'news' && currentText === 'news') {
    return true;
  }
  return false;
}

export default function Menu({ text, href }) {
  const { pathname } = useLocation();

  const currentText = text.toLowerCase();
  const isActive = getIsActive(currentText, pathname);

  return (
    <NavLinkBase
      isActive={isActive}
    >
      <Link to={href}>{text}</Link>
    </NavLinkBase>
  );
}
