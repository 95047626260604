import { initializeApp } from 'firebase/app';
import 'firebase/storage';
import { GoogleAuthProvider, SAMLAuthProvider, getAuth, signInWithPopup, signOut } from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appID: process.env.GATSBY_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
export const googleProvider = new GoogleAuthProvider();
export const gojekProvider = new SAMLAuthProvider(process.env.GATSBY_FIREBASE_SAML_PROVIDER);

export const logout = (afterAction = () => {}) => {
  signOut(auth).then(() => afterAction(null));
};

export const signInWithGoogle = async () => {
  await signInWithPopup(auth, gojekProvider);
};
