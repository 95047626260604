import { css } from 'styled-components';
import { textMD, textXL, textBold } from './typography';
import { marginBlock0 } from './utilities';

export const heading1 = css`
  ${textXL};
  font-size: 32px;
  letter-spacing: 0.3px;
  font-weight: 500;
  line-height: 48px;
  margin: 0;
  strong {
    ${textBold};
  }
`;

export const heading2 = css`
  margin: 0;
  ${textXL};
  letter-spacing: 0.52px;
  line-height: 42px;
  strong {
    ${textBold};
  }
`;

export const para = css`
  ${textMD};
  ${marginBlock0};
  margin: 0;
  padding: 0;
  line-height: 28px;
`;
