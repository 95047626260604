import React, { createContext, useContext, useState  } from 'react';
import { navigate } from 'gatsby';

import Loading from 'components/Loading';
import {
  LOGIN_URL,
} from 'data/urls';
import { auth } from 'utils/firebase';

const defaultContext = {
  isLoggedIn: false,
};

export const AuthContext = createContext(defaultContext);
export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children, location }) => {
  const [isLoggedIn, setIsLoggedin] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);

  auth.onAuthStateChanged(async user => {
    if (user !== null) {
      const isCurrUserLoggedIn = user.email !== undefined;
      setIsLoggedin(isCurrUserLoggedIn);
    }

    setAuthLoading(false);
  });

  if (authLoading) {
    return <Loading />;
  }

  if (!isLoggedIn) {
    if (location?.pathname === '/login') {
      navigate(LOGIN_URL, { state: { origin: location.state.origin } });
    } else {
      navigate(LOGIN_URL, { state: { origin: location?.pathname || '/' } });
    }
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedin }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthValue(){
  return useContext(AuthContext);
}

export default AuthProvider;