const CONSOLE_URL = 'http://console.data.golabs.io/';
const FEEDBACK_URL = `${CONSOLE_URL}api/feedback`;
// 'http://shield.integration.golabs.io/shield/api/v1/feedback';

const HOME_URL = '/'; // TODO: remove  from url
const CONTACT_US_URL = '/contact';
const DOCS_URL = '/docs';
const LOGIN_URL = '/login';
const METABASE_SITE_URL = 'http://i-godata-systems-metabase.golabs.io';
const TEAM_URL = 'https://go-jek.atlassian.net/l/c/jbfgoWLT';

const ABOUT_US_TAB_LINKS = [
  { title: 'Our Team', href: TEAM_URL },
];

const LOGO_PLACEHOLDER = '/images/logos/placeholder.svg';

module.exports = {
  ABOUT_US_TAB_LINKS,
  CONSOLE_URL,
  CONTACT_US_URL,
  DOCS_URL,
  FEEDBACK_URL,
  HOME_URL,
  LOGIN_URL,
  LOGO_PLACEHOLDER,
  METABASE_SITE_URL,
  TEAM_URL,
};
